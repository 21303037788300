export default {
  install(Vue) {
    Vue.component("icon", function() {
      return import("@/components/default/icon/index.vue");
    });
    Vue.component("inputText", function() {
      return import("@/components/default/form/inputText.vue");
    });
    Vue.component("inputTextarea", function() {
      return import("@/components/default/form/inputTextarea.vue");
    });
    Vue.component("inputEmail", function() {
      return import("@/components/default/form/inputEmail.vue");
    });
    Vue.component("inputPassword", function() {
      return import("@/components/default/form/inputPassword.vue");
    });
    Vue.component("inputSelect", function() {
      return import("@/components/default/form/inputSelect.vue");
    });
    Vue.component("inputOptional", function() {
      return import("@/components/default/form/inputOptional.vue");
    });
    Vue.component("toggleSwitch", function() {
      return import("@/components/default/form/toggleSwitch.vue");
    });
    Vue.component("toggleSwitch2", function() {
      return import("@/components/default/form/toggleSwitch2.vue");
    });
    Vue.component("saveBtn", function() {
      return import("@/components/default/form/saveBtn.vue");
    });
    Vue.component("info", function() {
      return import("@/components/default/form/info.vue");
    });
    Vue.component("help", function() {
      return import("@/components/default/form/help.vue");
    });
    Vue.component("range", function() {
      return import("@/components/default/form/range.vue");
    });
  }
};
