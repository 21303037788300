import Vue from "vue";

export default function() {
  this.data = "";
  this.store = "";
  this.assistent = "";

  this.save = function() {
    this.callbacks[0]();
  };
  this.checkUserData = function(ret) {
    if (!ret[0]) {
      this.callback(false);
      return false;
    }
    ret[1].md5_id = this.data.user;
    this.store.commit("save/setRegistredUser", ret[1], { root: true });
    this.callbacks[2]();
  };
  this.getUserData = function() {
    this.postData("user/get_user", { user: this.data.user }, this.callbacks[1]);
  };

  this.checkAssistentData = function(ret) {
    if (!ret[0]) {
      this.callback(false);
      return false;
    }

    this.assistent = ret[1];
    this.assistent.ID = this.data.ID;
    this.callbacks[4]();
  };

  this.getAssistentData = function() {
    if (typeof this.data.ID === "undefined" || !this.data.ID) {
      this.callbacks[4]();
      return false;
    }
    this.postData(
      "assistent/get_data",
      { user: this.data.user, ID: this.data.ID },
      this.callbacks[3]
    );
  };

  this.getServices = function() {
    this.postData(
      "assistent/get_services",
      { user: this.data.user },
      this.callbacks[5]
    );
  };
  this.setServices = function(ret) {
    if (!ret[0]) {
      this.callbacks[6]();
      return false;
    }
    this.store.commit("service/prefetch", ret[1], { root: true });
    this.store.commit("service/update", ret[1], { root: true });
    this.callbacks[6]();
  };

  this.callbacks = [
    function() {
      this.getUserData();
    }.bind(this),
    function(ret) {
      this.checkUserData(ret);
    }.bind(this),
    function() {
      this.getAssistentData();
    }.bind(this),
    function(ret) {
      this.checkAssistentData(ret);
    }.bind(this),
    function() {
      this.getServices();
    }.bind(this),
    function(ret) {
      this.setServices(ret);
    }.bind(this),
    function() {
      this.callback(true, this.assistent);
    }.bind(this)
  ];

  this.postData = function(url, data, callback) {
    Vue.prototype.$flix_post({
      url: url,
      data: data,
      callback: function(ret) {
        callback(ret.data);
      },
      api_url: this.store.rootState.save.apiUrl
    });
  };
}
