import register from "@/plugin/register";
import signIn from "@/plugin/signIn";
import saveAssistent from "@/plugin/saveAssistent";

const empty = {
  lastSaved: 0,
  error: false,
  success: false,
  trySave: false,
  userData: {
    username: "",
    pass: "",
    email: ""
  },
  registredUser: false,
  privateUser: false,
  apiUrl: false
};

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(empty)),
  getters: {},
  actions: {
    setSignIn(store, callback) {
      var s = new signIn();
      s.store = store;
      s.callback = callback;
      s.save();
    },
    setRegister(store, callback) {
      var s = new register();
      s.store = store;
      s.callback = callback;
      s.save();
    },
    updateSave(store, callback) {
      var s = new saveAssistent();
      s.store = store;
      s.callback = callback;
      s.save();
    },
    setUpdate(store, data) {
      store.commit("setError", false);
      var s = new saveAssistent();
      s.store = store;
      s.callback = function(error) {
        store.commit("setError", error);
      };
      s.setUpdateData(data);
    }
  },
  mutations: {
    setApiUrl(store, data) {
      store.apiUrl = data;
    },
    setLastSaved(store, date) {
      store.lastSaved = date;
    },
    clean(store) {
      var copy = JSON.parse(JSON.stringify(empty));
      Object.keys(copy).forEach(function(key) {
        store[key] = copy[key];
      });
    },
    setError(store, error) {
      store.error = error;
    },
    setRegistredUser(store, data) {
      store.registredUser = data;
    },
    setPrivateUser(store) {
      store.privateUser = true;
    },
    setUser(store, data) {
      store.userData = data;
    },
    setSave(store, data) {
      store.trySave = data;
    },
    setSuccess(store) {
      store.success = true;
    }
  }
};
