import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/preview",
    name: "preview",
    component() {
      return import("@/views/preview");
    }
  },
  {
    path: "/format",
    name: "format",
    component() {
      return import("@/views/format");
    }
  },
  {
    path: "/services",
    name: "services",
    component() {
      return import("@/views/services");
    }
  },
  {
    path: "/survey",
    name: "survey",
    component() {
      return import("@/views/survey");
    }
  },
  {
    path: "/styling",
    name: "styling",
    component() {
      return import("@/views/styling");
    }
  },
  {
    path: "/form",
    name: "form",
    component() {
      return import("@/views/form");
    }
  },
  {
    path: "/applicants",
    name: "applicants",
    component() {
      return import("@/views/applicants");
    }
  },
  {
    path: "/settings",
    name: "settings",
    component() {
      return import("@/views/settings");
    }
  },
  {
    path: "/time",
    name: "time",
    component() {
      return import("@/views/time");
    }
  },
  {
    path: "/private/styling",
    name: "privateStyling",
    component() {
      return import("@/views/private/styling");
    }
  },
  {
    path: "/private/time",
    name: "privateTime",
    component() {
      return import("@/views/private/time");
    }
  },
  {
    path: "/private/settings",
    name: "privateSettings",
    component() {
      return import("@/views/private/settings");
    }
  },
  {
    path: "/private/preview",
    name: "privatePreview",
    component() {
      return import("@/views/private/preview");
    }
  }
];

const router = new VueRouter({
  mode: "abstract",
  base: process.env.BASE_URL,
  routes
});

export default router;
