import getData from "@/plugin/getData";

const empty = {
  type: "",
  styling: {
    accent: "#8dc044"
  },
  survey: {
    type: "default",
    questions: false
  },
  startBtn: "",
  contactName: "",
  contactEmail: "",
  location: "",
  exclude: {
    weekdays: {},
    dates: [],
    lastminute: ""
  },
  autoconfirm: false,
  verifyTime: 2,
  include: {
    buffer: [0, 0, 0, 0, 0, 0, 0],
    weekdays: {
      0: ["08:00", "18:00", 60],
      1: ["08:00", "18:00", 60],
      2: ["08:00", "18:00", 60],
      3: ["08:00", "18:00", 60],
      4: ["08:00", "18:00", 60],
      5: ["08:00", "18:00", 60]
    },
    period: [2, "months"]
  },
  events: [],
  next_appointments: {
    0: 60,
    1: 60,
    2: 60,
    3: 60,
    4: 60,
    5: 60
  },
  max_applicants: {
    0: 1,
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1
  },
  showAppointments: false,
  bookingMethod: "default",
  title: "",
  start: "",
  end: "",
  date: false,
  form: []
};

export default {
  namespaced: true,
  state: {
    assistent: JSON.parse(JSON.stringify(empty)),
    unsaved: JSON.parse(JSON.stringify(empty)),
    weekday: false,
    copy: {}
  },
  getters: {},
  actions: {
    load(store, data) {
      var d = new getData();
      d.data = data.data;
      d.store = store;
      d.callback = function(state, ret) {
        if (!state) {
          return false;
        }
        if (!ret) {
          data.callback();
          return false;
        }
        store.commit("update", ret);
        store.commit("prefetch", ret);
        data.callback();
      };
      d.save();
    }
  },
  mutations: {
    clean(store) {
      store.assistent = JSON.parse(JSON.stringify(empty));
      store.unsaved = JSON.parse(JSON.stringify(empty));
    },
    update(store, data) {
      store.assistent = Object.assign(store.assistent, data);
    },
    prefetch(store, data) {
      store.unsaved = data;
    },
    setWeekday(store, data) {
      store.weekday = data;
    },
    setCopy(store, data) {
      store.copy = data;
    }
  }
};
