export default {
  namespaced: true,
  state: {
    view: "week",
    updateKey: 0
  },
  getters: {},
  actions: {},
  mutations: {
    setView(store, view) {
      store.view = view;
    },
    setUpdate(store) {
      store.updateKey = new Date().getTime();
    }
  }
};
