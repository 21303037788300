export default {
  namespaced: true,
  state: {
    unsaved: [],
    services: [],
    editService: 0,
    updateKey: 0
  },
  actions: {},
  mutations: {
    clean(store) {
      store.unsaved = [];
      store.services = [];
      store.editService = 0;
      store.updateKey = 0;
    },
    prefetch(store, data) {
      store.unsaved = data;
    },
    update(store, data) {
      store.services = data;
    },
    editService(store, data) {
      store.editService = data;
    },
    setUpdateKey(store) {
      store.updateKey = new Date().getTime();
    }
  },
  getters: {
    tags(store) {
      var r = {};
      var services = store.unsaved;
      Object.keys(services).forEach(function(index) {
        Object.keys(services[index].short).forEach(function(tag) {
          if (typeof r[services[index].short[tag]] === "undefined") {
            r[services[index].short[tag]] = services[index].short[tag];
          }
        });
      });
      return r;
    }
  }
};
