import components from "@/plugin/components";
import post from "@/plugin/post";
import embedCode from "@/plugin/embedCode";

export default {
  install(Vue) {
    embedCode.install(Vue);
    components.install(Vue);
    post.install(Vue, {
      post_url: process.env.VUE_APP_API_URL,
      url: process.env.VUE_APP_BASE_URL,
      brand: process.env.VUE_APP_BRAND,
      lang: "de"
    });
  }
};
