export default function(lang) {
  return {
    default: require("./" + lang + "/default.json"),
    dates: require("./" + lang + "/dates.json"),
    register: require("./" + lang + "/register.json"),
    format: require("./" + lang + "/format.json"),
    design: require("./" + lang + "/design.json"),
    time: require("./" + lang + "/time.json"),
    applicants: require("./" + lang + "/applicants.json"),
    settings: require("./" + lang + "/settings.json"),
    form: require("./" + lang + "/form.json"),
    survey: require("./" + lang + "/survey.json"),
    services: require("./" + lang + "/services.json"),
    private: require("./" + lang + "/private.json"),
    data: require("./" + lang + "/data.json")
  };
}
