import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import business from "@/store/business.js";
import privateEvent from "@/store/private.js";
import save from "@/store/save.js";
import form from "@/store/form.js";
import tableau from "@/store/tableau.js";
import service from "@/store/service.js";

export default function createStore() {
  return new Vuex.Store({
    namespaced: true,
    state: {
      type: "business"
    },
    mutations: {
      setType(store, type) {
        store.type = type;
      }
    },
    actions: {
      clean(store, callback) {
        store.commit("setType", "business");
        store.commit("service/clean", false, { root: true });
        store.commit("business/clean", false, { root: true });
        store.commit("privateEvent/clean", false, { root: true });
        store.commit("save/clean", false, { root: true });
        setTimeout(callback(), 100);
      }
    },
    modules: {
      business,
      privateEvent,
      form,
      save,
      tableau,
      service
    }
  });
}
