import Vue from "vue";
import lang from "@/lang/index.js";

export default function() {
  this.store = "";
  this.data = "";
  this.services = "";
  this.callback = function() {};
  this.error = "";
  this.user = "";
  this.lang = "";
  this.assistentID = "";
  this.checkService = false;

  this.save = function() {
    this.data = this.store.rootState.business.assistent;
    this.privateData = this.store.rootState.privateEvent.assistent;
    this.service = JSON.parse(
      JSON.stringify(this.store.rootState.service.services)
    );
    this.user = this.store.rootState.save.userData;

    var check = new Date();
    check.setSeconds(check.getSeconds() - 5);

    if (
      typeof window.quickstartLastSaved !== "undefined" &&
      check.getTime() <= window.quickstartLastSaved
    ) {
      return false;
    }

    this.callbacks[0]();
    window.quickstartLastSaved = new Date().getTime();
  };

  this.callbacks = [
    function() {
      this.saveAssistentToDb();
    }.bind(this),
    function(ret) {
      if (!ret[0]) {
        this.callback(false, ret[1].msg);
      } else {
        this.setAssistentID(ret);
      }
    }.bind(this),
    function() {
      this.setStandardReminder();
    }.bind(this),
    function() {
      this.sendCalendarInfoMail();
    }.bind(this),
    function() {
      this.getSavedServices();
    }.bind(this),
    function(ret) {
      this.getCheckServices(ret);
    }.bind(this),
    function() {
      this.saveService();
    }.bind(this),
    function() {
      this.callback(true, this.assistentID);
    }.bind(this)
  ];

  this.sendCalendarInfoMail = function() {
    var user = this.store.rootState.save.registredUser;
    var embedCode = Vue.prototype.$getEmbedCode({
      user: user.md5_id,
      ID: this.assistentID,
      language: user.language
    });
    var link =
      Vue.prototype.$flix_url +
      "/" +
      user.language +
      "/" +
      user.username +
      "/" +
      this.assistentID +
      "/index.html";
    var editLink =
      Vue.prototype.$flix_url +
      "/calendar-assistent/" +
      this.assistentID +
      ".html";

    this.postData(
      "assistent/sharingmail",
      {
        user: user.md5_id,
        to: user.email,
        title: lang
          .t("register.newAssistent.emailTitle")
          .split("{name}")
          .join(this.data.title),
        text: lang
          .t("register.newAssistent.emailText")
          .split("{name}")
          .join(this.data.title)
          .split("{link}")
          .join('<a href="' + link + '">' + link + "</a>")
          .split("{embedcode}")
          .join(
            '<code style="overflow-wrap: break-word; word-break: break-all;">' +
              embedCode
                .split("<")
                .join("&lt;")
                .split("[")
                .join("&lt;")
                .split("]")
                .join("&gt;")
                .split(">")
                .join("&gt;") +
              "</code>"
          )
          .split("{editLink}")
          .join('<a href="' + editLink + '">' + editLink + "</a>")
          .split("{supportcenter}")
          .join(
            '<a href="' +
              Vue.prototype.$flix_url +
              '/support-chat.html">' +
              Vue.prototype.$flix_url +
              "/support-chat.html</a>"
          )
      },
      this.callbacks[4]
    );
  };

  this.setStandardReminder = function() {
    var user = this.store.rootState.save.registredUser;
    this.postData(
      "reminder/standard",
      {
        user: user.md5_id,
        ID: this.assistentID
      },
      this.callbacks[3]
    );
  };

  this.setAssistentID = function(ret) {
    this.assistentID = ret[1].ID;
    this.callbacks[2]();
  };

  this.saveAssistentToDb = function() {
    var user = this.store.rootState.save.registredUser;
    var ID = "new";
    var callback = this.callbacks[1];

    if (
      typeof this.store.rootState.business.assistent.ID !== "undefined" &&
      this.store.rootState.business.assistent.ID
    ) {
      ID = this.store.rootState.business.assistent.ID;
      this.assistentID = ID;
      callback = this.callbacks[4];
    }

    this.postData(
      "assistent/save",
      {
        user: user.md5_id,
        ID: ID,
        data: JSON.stringify(this.store.rootState.business.assistent)
      },
      callback
    );
  };

  this.getCheckServices = function(ret) {
    if (!ret[0] || typeof ret[1] !== "object" || !Object.keys(ret[1]).length) {
      this.callbacks[6]();
      return false;
    } else {
      this.services = ret[1];
      this.callbacks[6]();
    }
  };

  this.getSavedServices = function() {
    if (!this.checkService) {
      this.callbacks[6]();
    }

    var user = this.store.rootState.save.registredUser;
    this.postData(
      "/assistent/get_services",
      {
        user: user.md5_id
      },
      this.callbacks[5]
    );
  };

  this.saveService = function() {
    var user = this.store.rootState.save.registredUser;
    var service = JSON.parse(JSON.stringify(this.service));

    if (!Object.keys(service).length) {
      this.callbacks[7]();
      return false;
    }

    Object.keys(service).forEach(function(k) {
      service[k].short = JSON.stringify(service[k].short);
      delete service[k].ID;
    });

    this.postData(
      "assistent/save_services",
      {
        user: user.md5_id,
        data: service
      },
      this.callbacks[7]
    );
  };

  this.postData = function(url, data, callback) {
    Vue.prototype.$flix_post({
      url: url,
      data: data,
      callback: function(ret) {
        callback(ret.data);
      },
      api_url: this.store.rootState.save.apiUrl
    });
  };

  this.setUpdateData = function(state) {
    if (state[0]) {
      var assistent = JSON.parse(
        JSON.stringify(this.store.rootState.business.assistent)
      );
      assistent.ID = state[1];
      this.store.commit("business/prefetch", assistent, { root: true });
      this.store.commit("business/update", assistent, { root: true });
      if (typeof window.flix_quickstart === "function") {
        window.flix_quickstart(
          this.store.rootState.business.assistent,
          this.store.rootState.service.services
        );
      }
    } else {
      this.store.commit("save/setError", state[1], { root: true });
    }
  };
}
