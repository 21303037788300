const empty = {
  ID: new Date().getTime(),
  type: "eventpicker",
  email: "",
  password: "",
  title: "",
  comments: "0",
  location: "",
  exclude: {
    anonym: "0",
    lastminute: "0"
  },
  max_applicants: {},
  form: "privateEvent",
  events: []
};

export default {
  namespaced: true,
  state: {
    assistent: JSON.parse(JSON.stringify(empty)),
    unsaved: JSON.parse(JSON.stringify(empty)),
    weekday: false,
    copy: {}
  },
  getters: {},
  actions: {},
  mutations: {
    clean(store) {
      store.assistent = JSON.parse(JSON.stringify(empty));
      store.unsaved = JSON.parse(JSON.stringify(empty));
    },
    update(store, data) {
      store.assistent = Object.assign(store.assistent, data);
    },
    prefetch(store, data) {
      store.unsaved = data;
    }
  }
};
