import Vue from "vue";
import App from "./App.vue";
import plugin from "@/plugin/index.js";
import i18n from "@/lang/index.js";

Vue.config.productionTip = false;

Vue.use(plugin);

new Vue({
  i18n,
  render: h => h(App)
}).$mount("flix-assistent");
