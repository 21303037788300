<template>
  <div id="flix-quickstart" :key="updateKey">
    <div class="container">
      <div class="stage">
        <div
          class="switcher"
          v-if="$route.name === 'format' || $route.name === 'privateStyling'"
        >
          <switcher />
        </div>
        <transition name="pageSwitch">
          <router-view />
        </transition>
      </div>
      <div>
        <assistentNavigation />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import router from "./router";
import createStore from "./store";
import i18n from "@/lang/index.js";
import plugin from "@/plugin/index.js";
import calendar from "bookingflix_calendar";

Vue.use(calendar);
Vue.use(plugin);

export default {
  router,
  i18n,
  beforeCreate() {
    this.$store = createStore();
    this.$store.dispatch(
      "clean",
      function() {
        this.$router.push("/");
      }.bind(this)
    );
  },
  components: {
    assistentNavigation() {
      return import("@/components/navigation/index.vue");
    },
    switcher() {
      return import("@/components/switcher/index.vue");
    }
  },
  props: {
    lang: {
      type: String,
      default() {
        return "de";
      }
    },
    data: {
      type: String,
      default() {
        return "";
      }
      // default () { return 'eyJ1c2VyIjoiMTNmOTg5NmRmNjEyNzljOTI4ZjE5NzIxODc4ZmFjNDEiLCJJRCI6IjE2MTE3Mzc3MTQifQ==' }
      // default () { return btoa(JSON.stringify({ user: "1896a3bf730516dd643ba67b4c447d36", ID: "1614800836" })) }
    }
  },
  data() {
    return {
      updateKey: 0
    };
  },
  computed: {},
  mounted() {
    if (
      typeof this.$route !== "undefined" &&
      typeof this.$route.name !== "undefined" &&
      this.$route.name === null
    ) {
      this.$router.push({ name: "format" });
    }
    this.setLanguage();
    this.setData();
  },
  methods: {
    setLanguage() {
      this.$i18n.locale = this.lang;
      this.$flix_post_lang(this.lang);
    },
    setData() {
      var data = "";

      if (typeof this.data === "undefined" || !this.data) {
        return false;
      }
      if (typeof this.data === "string") {
        data = JSON.parse(atob(this.data));
      }
      // window.flix_quickstart = function (assistent, service) { window.console.log(assistent, service) }
      if (typeof data.api_url !== "undefined" && data.api_url) {
        this.$store.commit("save/setApiUrl", data.api_url);
      }
      this.$store.dispatch("business/load", {
        data: data,
        callback: function() {
          this.updateKey = new Date().getTime();
        }.bind(this)
      });
    }
  }
};
</script>

<style lang="sass">

@keyframes openPage
  from
    transform: scaleX(0)
    opacity: 0
  to
    transform: scaleX(1)
    opacity: 1

#flix-quickstart
  @import '@/assets/sass/main'
  max-width: 1200px
  margin: 0 auto
  @include getFont($font-2, 12pt, 400)
  .pageSwitch-enter-active
    transform-origin: center left
    animation: 0.3s openPage ease-in-out
</style>

<style lang="sass" scoped>
@import '@/assets/sass/variables'
$radius: 7px
$padding: 60px
$shadow: 0px 5px 10px rgba(0,0,0,0.4)
.container
  .stage
    box-shadow: $shadow
    min-height: 50vh
    border-top-left-radius: $radius
    border-top-right-radius: $radius
    padding: $padding
    box-sizing: border-box
  .menu
    padding: 0
    overflow: hidden
    box-shadow: $shadow
    display: block
    border-bottom-left-radius: $radius
    border-bottom-right-radius: $radius
  @media (max-width: $mobile)
    .stage
      padding: 10px
    .menu
      padding: 0
</style>
