import Vue from "vue";
import lang from "@/lang/index.js";
import saveAssistent from "./saveAssistent.js";

export default function() {
  this.store = "";
  this.data = "";
  this.services = "";
  this.callback = function() {};
  this.error = "";
  this.user = "";

  this.save = function() {
    this.user = this.store.rootState.save.userData;
    this.callbacks[0]();
  };

  this.callbacks = [
    function() {
      this.getSignIn();
    }.bind(this),
    function(ret) {
      this.checkRegisterUser(ret);
    }.bind(this),
    function() {
      var s = new saveAssistent();
      s.checkService = true;
      s.store = this.store;
      s.callback = this.callback;
      s.save();
    }.bind(this)
  ];

  this.postData = function(url, data, callback) {
    Vue.prototype.$flix_post({
      url: url,
      data: data,
      callback: function(ret) {
        callback(ret.data);
      },
      api_url: this.store.rootState.save.apiUrl
    });
  };

  this.getSignIn = function() {
    this.postData(
      "/user/get_login",
      {
        username: this.user.username,
        password: this.user.pass
      },
      this.callbacks[1]
    );
  };

  this.checkRegisterUser = function(ret) {
    if (!ret[0]) {
      this.error = lang.t("register.loginIncorrect");
      this.callback(false, this.error);
      return false;
    }
    this.user = ret[1];
    this.store.commit("setRegistredUser", ret[1]);
    this.callbacks[2]();
  };
}
