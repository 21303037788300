import axios from "axios";
var qs = require("qs");

export default {
  install(Vue, options) {
    Vue.prototype.$flix_post_lang = function(lang) {
      options.lang = lang;
    };
    Vue.prototype.$flix_post = function(data, url) {
      if (typeof url === "undefined") {
        options.url = options.post_url;
      } else {
        options.url = options[url];
      }

      if (typeof data.api_url !== "undefined" && data.api_url) {
        options.url = data.api_url;
      }
      const post = axios.create({ baseURL: options.url + "/" + options.lang });

      if (typeof data.data !== "object") {
        data.data = {};
      }

      post.post(data.url, qs.stringify(data.data)).then(function(ret) {
        if (typeof data.callback === "function") {
          data.callback(ret);
        } else {
          console.log(ret);
        }
      });
    };
    Vue.prototype.$flix_url = options.url;
    Vue.prototype.$flix_brand = options.brand;
  }
};
