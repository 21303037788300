export default {
  namespaced: true,
  state: {
    edit: false,
    newElement: false
  },
  getters: {},
  actions: {},
  mutations: {
    setEdit(store, id) {
      store.edit = id;
    },
    setNewElement(store, id) {
      store.newElement = id;
    }
  }
};
